<template>
    <div>
        <v-container grid-list-md text-xs-center fluid mb-16>
            <h1>Integração</h1>
            <v-divider class="mb-4" />
            <v-text-field :label="$t('Forçar sincronização de pacote')" v-model="ticket" clearable v-on:keyup.enter="sendSync" append-outer-icon="mdi-send"
                @click:append-outer="sendSync" />
            <v-divider class="my-4" />
            <v-data-table :headers="headers" :items="items" :options.sync="options" :server-items-length="itemsTotal" :loading="loading" class="elevation-1">

                <template v-slot:item.status="{ item }">
                    <v-chip v-if="item.log.filter(i => i.status == 'CREATED').length > 0" class="ma-2" color="success" outlined>
                        <v-icon left> done </v-icon>
                        {{ item.log.filter(i => i.status == 'CREATED').length }}
                    </v-chip>
                    <v-chip v-if="item.log.filter(i => i.status != 'CREATED').length > 0" class="ma-2" color="warning" outlined>
                        <v-icon left> warning </v-icon>
                        {{ item.log.filter(i => i.status != 'CREATED').length }}
                    </v-chip>
                </template>

                <template v-slot:item.createdAt="{ item }">
                    {{ item.createdAt ? $moment(item.createdAt).format("DD/MM/YY HH:mm") : "-" }}
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-btn class="ma-2" color="info" dark small @click="showDetailsModal(item)">
                        <v-icon dark small>preview</v-icon>
                    </v-btn>
                </template>

            </v-data-table>
        </v-container>
        <Details :show="showDetails" :items="details" @close="showDetails = false" />
    </div>
</template>

<script>
import Details from "./Details.vue";
export default {
    components: { Details },
    data() {
        return {
            itemsTotal: 0,
            items: [],
            loading: true,
            options: { itemsPerPage: 10, page: 1 },
            details: null,
            ticket: null,
            showDetails: false,
            headers: [
                { text: this.$t("#"), value: "id", align: "left", sortable: true },
                { text: this.$t("Data"), value: "createdAt", align: "right", sortable: true },
                { text: this.$t("Ponteiro NEC"), value: "pointInDatabase", align: "right", sortable: false },
                { text: this.$t("Integrações"), value: "status", align: "right", sortable: false },
                { text: this.$t("Mais informações"), value: "actions", align: "right", sortable: true },
            ],
        };
    },

    created() {
        this.get();
    },

    methods: {
        sendSync() {
            this.$http.post(`integrations`, { ticket: this.ticket })
                .then(() => {
                    this.$eventHub.$emit("msgSuccess", "Pacote integrado com sucesso.");
                })
                .catch(() => {
                    this.$eventHub.$emit("msgError", "Erro ao localizar pacote.");
                });

        },
        showDetailsModal(item) {
            console.log(item.log)
            this.details = item.log;
            this.showDetails = true;
        },
        synchronize() {
            this.$http.get(`integrations/synchronize`).then(() => this.get());
        },
        get() {
            this.loading = true
            this.$http
                .get(`v2/integrations?skip=${this.options.itemsPerPage * (this.options.page - 1)}&limit=${this.options.itemsPerPage}`)
                .then((result) => {
                    this.items = result.items
                    this.itemsTotal = result.count
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                });
        },
    },
};
</script>